import React from 'react'
import DefaultMaster from '../components/masters/DefaultMaster/DefaultMaster'
import Customers from '../components/containers/Customers/Customers'

function AboutPage() {
  return (
    <DefaultMaster title="Clientes">
      <Customers />
    </DefaultMaster>
  )
}

export default AboutPage
