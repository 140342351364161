import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'

import { TextBlock as TB, Separator } from '../../core/styles'
import Img from 'gatsby-image'

import constants from '../../../config/constants'
import spacings from '../../../config/spacings'
import colors from '../../../config/colors'

function Customers() {
  const {
    customers: { nodes: customers },
  } = useStaticQuery(graphql`
    query {
      customers: allCustomersJson {
        nodes {
          name
          fullName
          site
          logo {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const buildCustomers = (el, idx) => {
    if (el.logo.childImageSharp === null) {
      return (
        <a key={idx.toString()} href={el.site}>
          <img alt={el.name} src={el.logo.publicURL} />
        </a>
      )
    }

    let maxWidth = (el.logo.childImageSharp.fluid.sizes.match(/\d+px$/) || [
      'auto',
    ])[0]

    return (
      <a key={idx.toString()} href={el.site}>
        <StyledImg
          maxWidth={maxWidth}
          alt={el.name}
          fluid={el.logo.childImageSharp.fluid}
        />
      </a>
    )
  }

  return (
    <Container>
      <Wrapper>
        <h2>Clientes</h2>
        <ContentWrapper>
          <TextBlock>
            Nossos clientes são de fundamental importância para a empresa, sendo
            um de nossos focos entregar qualidade e confiança para cada um
            deles. Segue abaixo uma lista dos nossos mais queridos e satisfeitos
            clientes!
          </TextBlock>
          <Separator />
          <h3>Alguns de nossos clientes</h3>
          <ShowdownContainer>{customers.map(buildCustomers)}</ShowdownContainer>
        </ContentWrapper>
      </Wrapper>
    </Container>
  )
}

const Container = styled.main`
  flex-grow: 1;

  display: flex;
  flex-direction: column;
`

const Wrapper = styled.section`
  max-width: ${constants.sizes.maxWidth}px;
  width: 100%;

  flex-grow: 1;

  margin: 0 auto;

  padding: ${spacings.large}rem;

  display: flex;
  flex-direction: column;

  color: ${colors.light.text};

  @media only screen and (max-width: ${constants.sizes.medium}px) {
    align-items: center;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  align-items: center;

  align-self: center;
`

const TextBlock = styled(TB)`
  max-width: 60rem;
`

const ShowdownContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  max-width: 800px;

  & > a {
    flex-basis: calc(100% / 3 - ${spacings.large}rem);
    min-width: 150px;

    display: flex;
    align-items: center;
    justify-content: center;

    margin: ${spacings.medium}rem ${spacings.large / 2}rem;

    filter: saturate(0.7) grayscale(1);

    & * {
      width: 100%;
    }
  }
`

const StyledImg = styled(Img)`
  max-width: ${({ maxWidth }) => maxWidth || 'auto'};
`

export default Customers
